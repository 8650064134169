// Script files that should be included in all pages
import 'intersection-observer';
import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/button';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/scrollspy';
import runBrowserUpdate from '../src/javascripts/_browser-update';
import initDOM, * as www from '../src/javascripts/www/_main';
import sidebarTypeaheads from '../src/javascripts/www/_typeaheads';
import { animateSearchIcon, bindDismissBannerCookie, initTooltips, initPopovers, linkify } from "../src/javascripts/_ui";
import './base.scss';


document.addEventListener("DOMContentLoaded", () => {
  Rails.start();
  Turbolinks.start();
});

// Modal manually imported, otherwise isn't available for blacklight modal
import 'bootstrap/js/dist/modal';
import 'blacklight-frontend/app/javascript/blacklight/core';
import 'blacklight-frontend/app/javascript/blacklight/modal';
import 'blacklight-frontend/app/javascript/blacklight/button_focus';
// Import overridden search_context for google analytics
import '../src/javascripts/override/search_context';


document.addEventListener('turbolinks:load', () => {
  animateSearchIcon();
  initTooltips();
  initPopovers();
  initDOM();
  linkify();
  bindDismissBannerCookie();
  sidebarTypeaheads();
  Blacklight.modal.setupModal();
  Blacklight.doSearchContextBehavior();
  runBrowserUpdate();
});
